import { Component, Injector, Input } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModelFormModalComponent } from '@ade/core/src/modals';
import { InjectionService, StoreService } from '@ade/core/src/services';
import { CaseRecord } from '@ade/ruckus/src/models';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'case-assign-serial-number-modal',
    templateUrl: 'assign_serial_number.component.html'
})
export class AssignSerialNumberModalComponent extends ModelFormModalComponent {
    public store: StoreService;

    @Input()
    public item: CaseRecord;

    public alertSubject = 'case-assign-serial-number-modal';

    public constructor(
        protected injection: InjectionService,
        protected injector: Injector
    ) {
        super(injection, injector);
        this.store = this.getService(StoreService);
    }

    public createFormControls(): any {
        const data = {
            'serial_number': ''
        };

        return {
            'serial_number': [data['serial_number'], [Validators.required]],
        };
    }

    public getFormData(): object {
        const data = this.form.value;

        return {
            'info': {
                'serials': [data.serial_number]
            }
        };
    }
}
