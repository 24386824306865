export class DeviceDiscovery {
    product_name: string;
    serial_number: string;
    software_version?: string;
    portal_id?: number;
}

export class UploadResult {}
export class RemoteAccessEnable {}
export class RemoteAccessDisable {}
export class RemoteAccessStatus {
    status?: boolean;
}

export interface Integrations {
    deviceDiscovery?: boolean;
    uploadLogs?: boolean;
    uploadConfigs?: boolean;
    uploadSupports?: boolean;
    enableRemoteAccess?: boolean;
    disableRemoteAccess?: boolean;
    statusRemoteAccess?: boolean;
}

export enum IntegrationType {
    DEVICE_DISCOVERY = 'deviceDiscovery',
    UPLOAD_LOGS = 'uploadLogs',
    UPLOAD_CONFIGS = 'uploadConfigs',
    UPLOAD_SUPPORTS = 'uploadSupports',
    ENABLE_REMOTE_ACCESS = 'remoteAccessEnable',
    DISABLE_REMOTE_ACCESS = 'remoteAccessDisable',
    STATUS_REMOTE_ACCESS = 'remoteAccessStatus'
}
