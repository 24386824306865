import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Import 3rd party components
import { NgxFlowModule  } from '@flowjs/ngx-flow';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ComponentsModule as CoreComponentsModule } from '@ade/core/src/components';
import { ComponentsModule as RemoteComponentsModule } from '@ade/remote/src/components';

import {
    ToolsDashboardComponent
} from './dashboards';
import {
    BuildReleaseDatatableComponent,
    BuildSourceDatatableComponent,
    CloudAssetDatatableComponent,
    CloudDelegationDatatableComponent,
    CloudLicenseDatatableComponent,
    CloudLogDatatableComponent,
    CloudMspEndCustomerDatatableComponent,
    CloudMspLicenseAssignmentDatatableComponent,
    CloudOutofsyncServiceDatatableComponent,
    CaseDatatableComponent,
    DirectoryMetaDatatableComponent,
    ExportTenantsDatatableComponent,
    JiraIssueDatatableComponent,
    RMACertificateDatatableComponent,
    Rpointv2DeviceDatatableComponent,
    TaskDatatableComponent,
    UmmAssetDatatableComponent
} from './datatables';
import {
    DecodeCorefileModalComponent,
    DecodeDmrawModalComponent,
    DecodePtracesnmpModalComponent,
    DecodeStacktraceModalComponent,
    ExportTenantsModalComponent,
    IcxCodeDownloaderModalComponent,
    JiraIssueModalComponent,
    RMACheckModalComponent,
    SfdcDownloaderModalComponent,
    TerminalModalComponent,
    LicenseGeneratorModalComponent
} from './modals';
import {
    AssignCloudTenantModalComponent,
    AssignSerialNumberModalComponent,
    DisableCloudSupportModalComponent,
    EnableCloudSupportModalComponent,
    RefreshCloudCacheModalComponent,
    RefreshRpointv2CacheModalComponent,
    RefreshUmmCacheModalComponent
} from './modals/case';
import {
    DiscoveryModalComponent,
    IndexingModalComponent
} from './modals/directory_meta';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        BsDropdownModule.forRoot(),
        CoreComponentsModule.forRoot(),

        // 3rd party module
        NgxFlowModule,
        TabsModule.forRoot(),

        CoreComponentsModule.forRoot(),
        RemoteComponentsModule.forRoot(),
    ],
    declarations: [
        // dashboard components
        ToolsDashboardComponent,

        // datatable components
        BuildReleaseDatatableComponent,
        BuildSourceDatatableComponent,
        CloudAssetDatatableComponent,
        CloudDelegationDatatableComponent,
        CloudLicenseDatatableComponent,
        CloudLogDatatableComponent,
        CloudMspEndCustomerDatatableComponent,
        CloudMspLicenseAssignmentDatatableComponent,
        CloudOutofsyncServiceDatatableComponent,
        CaseDatatableComponent,
        DirectoryMetaDatatableComponent,
        ExportTenantsDatatableComponent,
        RMACertificateDatatableComponent,
        Rpointv2DeviceDatatableComponent,
        JiraIssueDatatableComponent,
        TaskDatatableComponent,
        UmmAssetDatatableComponent,

        // modal components
        AssignCloudTenantModalComponent,
        AssignSerialNumberModalComponent,
        DecodeCorefileModalComponent,
        DecodeDmrawModalComponent,
        DecodePtracesnmpModalComponent,
        DecodeStacktraceModalComponent,
        DisableCloudSupportModalComponent,
        EnableCloudSupportModalComponent,
        ExportTenantsModalComponent,
        IcxCodeDownloaderModalComponent,
        JiraIssueModalComponent,
        RefreshCloudCacheModalComponent,
        RefreshRpointv2CacheModalComponent,
        RefreshUmmCacheModalComponent,
        RMACheckModalComponent,
        SfdcDownloaderModalComponent,
        TerminalModalComponent,
        LicenseGeneratorModalComponent,

        DiscoveryModalComponent,
        IndexingModalComponent,
    ],
    exports: [
        // dashboard components
        ToolsDashboardComponent,

        // datatable components
        BuildReleaseDatatableComponent,
        BuildSourceDatatableComponent,
        CloudAssetDatatableComponent,
        CloudDelegationDatatableComponent,
        CloudLicenseDatatableComponent,
        CloudLogDatatableComponent,
        CloudMspEndCustomerDatatableComponent,
        CloudMspLicenseAssignmentDatatableComponent,
        CloudOutofsyncServiceDatatableComponent,
        CaseDatatableComponent,
        DirectoryMetaDatatableComponent,
        ExportTenantsDatatableComponent,
        JiraIssueDatatableComponent,
        RMACertificateDatatableComponent,
        Rpointv2DeviceDatatableComponent,
        TaskDatatableComponent,
        UmmAssetDatatableComponent,

        // modal components
        AssignCloudTenantModalComponent,
        AssignSerialNumberModalComponent,
        DecodeCorefileModalComponent,
        DecodeDmrawModalComponent,
        DecodePtracesnmpModalComponent,
        DecodeStacktraceModalComponent,
        DisableCloudSupportModalComponent,
        EnableCloudSupportModalComponent,
        ExportTenantsModalComponent,
        IcxCodeDownloaderModalComponent,
        JiraIssueModalComponent,
        RefreshCloudCacheModalComponent,
        RefreshRpointv2CacheModalComponent,
        RefreshUmmCacheModalComponent,
        RMACheckModalComponent,
        SfdcDownloaderModalComponent,
        TerminalModalComponent,
        LicenseGeneratorModalComponent,

        DiscoveryModalComponent,
        IndexingModalComponent,
    ],
    entryComponents: [
        // dashboard components
        ToolsDashboardComponent
    ]
})
export class ComponentsModule {
    public static forRoot(): ModuleWithProviders<ComponentsModule> {
        return {
            ngModule: ComponentsModule,
            providers: []
        };
    }
}
