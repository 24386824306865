import { Component, Injector, ChangeDetectorRef  } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModelFormModalComponent } from '@ade/core/src/modals';
import { InjectionService } from '@ade/core/src/services';
import { SpaCodeFactory, LicenseKeyUpgradeFactory, LicenseKeyUpgradeRecord, SpaCodeRecord } from '../../models';
import { Mapper } from 'js-data';
import { ContactFactory } from '@ade/sfdc/src/models';

@Component({
  selector: 'license-generator-modal',
  templateUrl: './license_generator.component.html',
  styleUrls: ['./license_generator.component.scss']
})
export class LicenseGeneratorModalComponent extends ModelFormModalComponent {
  
  public spaCode: SpaCodeRecord = null;
  public lku: LicenseKeyUpgradeRecord = null;
  public contactMapper: Mapper;
  public selectedContact: any = null;
  public canActivate: boolean = false;
  public isFinished: boolean = false;
  public modalClass = 'modal-lg';

  public constructor(
    protected injection: InjectionService,
    protected injector: Injector,
    protected changeDetector: ChangeDetectorRef,
  ) {
    super(injection, injector);
    this.messageDuration = 0;
    this.contactMapper = this.store.getMapper(ContactFactory.model);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createFormControls();
  }

  public show(): void {
    this.resetData();
    super.show();
    this.selectedContact = null;
  }

  public createFormControls(): object {
    return {
      'contact_sfdc_id': ['', Validators.required],
      'code': ['', Validators.required]
    };
  }

  public async submit(): Promise<void> {
    this.resetData();
    this.submitted = true;

    if (!this.form.valid) {
      this.alertError('Please select the Contact and enter the Activation Code');
      return;
    }

    const code = this.form.controls['code'].value;
    const contact_id = this.form.controls['contact_sfdc_id'].value;
    this.alertNormal('Validating Activation Code');

    try {

     
      const spaCodeResponse = await this.findSpaCode(code);
      this.spaCode = spaCodeResponse ? spaCodeResponse[0] : null;

      if (this.spaCode?.status.isValid) {
        if (this.spaCode.code_type !== 'Support' && this.spaCode.code_type !== 'ACX') {
          const lkuResponse = await this.findLku(code);
          this.lku = lkuResponse ? lkuResponse[0] : null;
        }
      }

      const validationResponse = await this.validateSpaCode(code, contact_id);
      this.validation = validationResponse ? validationResponse[0] : null;

      
      if (this.validation) {
        this.alertError(this.validation)
      
      } else {
        this.canActivate = true;
        this.form.controls['code'].disable();
        this.form.controls['contact_sfdc_id'].disable();

        this.handleResponse(this.spaCode.status.message)
      }


    } catch (error) {
      console.error('Error:', error);
      this.handleError();
    }
  }

  private async validateSpaCode(spa_code: string, contact_id: string): Promise<any> {
    try {
        const response = await this.http.post(
            `${SpaCodeFactory.endpoint}${spa_code}/validate/`,
            { contact_id } 
        ).toPromise();
        
        return response;
    } catch (error) {
        console.error('Error in verification:', error);
        throw error;
    }
  }


  private async findSpaCode(code: string): Promise<any> {
    try {
      return await this.store.findAll(SpaCodeFactory.model, { 'sfdc_id': code });
    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }

  private async findLku(code: string): Promise<any> {
    try {
      return await this.store.findAll(LicenseKeyUpgradeFactory.model, { 'license_code': code });
    } catch (error) {
      console.error('Error in API call:', error);
      throw error;
    }
  }

  public async activate(): Promise<void> {
    this.resetAlert()

    // SPA Registration

    if (this.spaCode.code_type === "Support" || this.spaCode.code_type === "ACX") {
      return this.alertError("SPA Activation is not supported yet")
    } else {

      // License Upgrade Registration

      if (!this.lku || this.lku.status === 'Inactive') {
        return this.alertError("Invalid License Upgrade")
      }

      if (this.lku.is_used) {
        return this.alertError("License is already bound to asset")
      }

      if (this.lku.require_docusign) {
        return this.alertError("Docusing Implementation is pending")
      }

      if(this.lku.is_flexera_product && !this.lku.is_rtu_license && this.lku.send_to_flexera){
        
        this.alertNormal("Activating Code...")
          try {
            const contact_id = this.form.controls['contact_sfdc_id'].value;
            const response: any = await this.http.post(
                `${LicenseKeyUpgradeFactory.endpoint}${this.lku.license_code}/activate/`,
                { contact_id } 
            ).toPromise();
            
            if (response.isSuccess) {
              const lkuResponse = await this.findLku(this.lku.license_code);
              this.lku = lkuResponse ? lkuResponse[0] : null;
              this.handleResponse(response.message); // Call the success handler
              this.isFinished = true;
            } else {
                this.alertError(response.message); // Display error message
            }
        } catch (error) {
            console.error('Error in activation:', error);
            throw error;
        }
        
      }else{
        return this.alertError("Activation Code not supported yet")
      }
    }
  }

  public clearActivation(): void {
    this.form.reset();
    this.form.controls['code'].enable();
    this.form.controls['contact_sfdc_id'].enable();
    this.isFinished = false;
  }

  private resetData(): void {
    this.canActivate = false;
    this.spaCode = null;
    this.lku = null;
    this.submitted = false;
  }

  private resetAlert(): void {
    this.alert.normal('', {
      subject: this.alertSubject,
      autoClose: true,
    });
  }

  public onContactSelected(selectedData: any[]): void {
    if (!selectedData || selectedData.length === 0) {
      this.selectedContact = null;
      this.resetData();
      this.resetAlert();
    } else {
      this.selectedContact = selectedData[0].item;
    }
  }
  
  public normalizeContactSearch(term: string): string {
    return term.toLowerCase();
  }

  private alertError(message: string): void {
    this.alert.normal(`<span class="alert alert-danger p-1"><i class="fa fa-warning text-red"></i> ${message}</span>`, {
      subject: this.alertSubject,
      duration: this.messageDuration,
    });
  }

  private alertNormal(message: string): void {
    this.alert.normal(`<span class="alert alert-light p-1"><i class="fa fa-spinner fa-spin"></i> ${message}</span>`, {
      autoClose: false,
      subject: this.alertSubject,
    });
  }

  private alertSuccess(message: string): void {
    this.alert.success(`<span class="alert alert-success p-1"><i class="fa fa-check text-green"></i> ${message}</span>`, {
      autoClose: true,
      subject: this.alertSubject,
      duration: this.messageDuration,
    });
  }

  private handleResponse(message: string): void {
    this.alertSuccess(message);
    this.changeDetector.detectChanges();
  }

  private handleError(): void {
    this.alertError('Error');
  }

}
