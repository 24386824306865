import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    isDevMode,
    AfterViewInit,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    OnInit
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { iframeResizer } from 'iframe-resizer';
import $ from 'jquery';
import shortid from 'shortid';
import { BasePaneComponent } from '@ade/core/src/panes';
import { InjectionService } from '@ade/core/src/services';
import * as liveperson from '../../../liveperson';
import { FrameService, LivepersonService, StorageService, UtilsService } from '../../../services';
import { AppConfig, APP_CONFIG } from '@ade/core/src/config';
import { Router } from '@angular/router';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'chat-messenger-pane',
    templateUrl: 'messengerpane.component.html',
    styleUrls: ['./messengerpane.component.scss'],
})
export class MessengerPaneComponent extends BasePaneComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
    @Input()
    public isOpen: boolean;

    @Input()
    public message: liveperson.Message;

    @Input()
    public url = 'about:blank';

    public safeUrl: SafeUrl;

    /**
     * The current active sequence number representing the latest message received
     */
    @Input()
    public activeNum: number;

    @Output()
    public done: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public elevate: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('iframe')
    public iframe: ElementRef;

    protected appConfig: AppConfig;
    protected router: Router;
    protected frame: FrameService;
    protected liveperson: LivepersonService;
    protected storage: StorageService;
    protected utils: UtilsService;
    protected frameInitialized = false;
    protected frameId = '';

    public constructor(
        protected injection: InjectionService,
        protected injector: Injector,
        protected changeDetectorRef: ChangeDetectorRef,
        protected sanitizer: DomSanitizer
    ) {
        super(injection, injector);
        this.router = this.getService(Router);
        this.frame = this.getService(FrameService);
        this.liveperson = this.getService(LivepersonService);
        this.storage = this.getService(StorageService);
        this.utils = this.getService(UtilsService);
        this.frameId = shortid.generate();
        this.appConfig = this.getService(APP_CONFIG);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.activeNum) {
            if (changes.activeNum) {
                const activeNum = changes.activeNum.currentValue;
                if (activeNum > this.message.num && this.iframe) {
                    try {
                        // we must notify our child frame that our sequence has changed
                        this.frame.sendChildMessage({
                            'type': 'num',
                            'data': {
                                'newNum': activeNum,
                                'ourNum': this.message.num
                            }
                        }, this.iframe.nativeElement.contentWindow);
                    } catch (e) {
                        // we tried our best
                        console.error(e);
                    }
                }
            }
        }

        if (changes.url) {
            await this.applySafeUrl(changes.url.currentValue);
        }
    }

    public async applySafeUrl(url: string): Promise<void> {
        // we must convert the URL from liveperson
        const urlObj = new URL(url);
        if (urlObj.hostname.includes('liveperson.net')) {
            url = await this.utils.normalizeWidgetUrl(url);
        }

        // when we are in dev mode, we will replace the URL with ours if the host is tdi-stg.ph-rw.net
        if (isDevMode && url) {
            const ourObj = new URL(window.location.href);
            if (/^(([0-9]{1,3}\.){3}[0-9]{1,3})$/.test(ourObj.hostname)) {
                // this is an IP based solution, we will adjust it to use our it
                const urlObj = new URL(url);
                if (urlObj.hostname === 'tdi-stg.ph-rw.net' || urlObj.hostname === 'tdi-stg.ruckus.support') {
                    if (window != window.parent) {
                        // we are in an iframe, likely we are running chat/demo1
                        // since Angular 9 has issues of 3 level deep iframe, we point to our prod build
                        url = `${ourObj.protocol}//${ourObj.host}${urlObj.pathname}${urlObj.hash}`;
                    } else{
                        // we are not in an iframe, likely we are running chat/demo
                        url = `${ourObj.origin}${urlObj.pathname}${urlObj.hash}`;
                    }
                }
            }
        }

        if (url) {
            if (url.indexOf('?') > -1) {
                url = `${url}&child_id=${this.frameId}`;
            } else {
                url = `${url}?child_id=${this.frameId}`;
            }

            if (this.message) {
                // add the sequence to the URL
                url = `${url}&sequence=${this.message.sequence}`;
            }

            if (this.liveperson.hasToken()) {
                // add the token
                url = `${url}&token=${this.liveperson.getToken()}`;
            }
            // if appconfig has skin, then inject the skin queryparam
            if (this.appConfig.app_skin) {
                url = `${url}&skin=${this.appConfig.app_skin}`;
            }

            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('about:blank');
        }

        if (this.safeUrl && !this.frameInitialized) {
            this.initializeIframe();
        }
    }

    public ngOnInit(): void {
        this.frame.subscribeChildMessage(this.frameId, this.handleChildMessage.bind(this));
        this.frame.subscribeParentMessage(this.handleParentMessage.bind(this));
    }

    public ngAfterViewInit(): void {
        if (this.safeUrl && !this.frameInitialized) {
            // yea we have a valid safe url that we can initialize this iframe
            this.initializeIframe();
        }
    }

    public ngOnDestroy(): void {
        this.frame.unsubscribeChildMessage(this.frameId);
        this.frame.unsubscribeParentMessage(this.handleParentMessage.bind(this));
        super.ngOnDestroy();
    }

    public initializeIframe(): void {
        if (this.iframe) {
            iframeResizer({
                log: false,
                checkOrigin: false,
                sizeHeight: false,
                sizeWidth: false,
                scrolling: true,
                resizedCallback: (event: {iframe; height; width; type}): void => {
                    // add custom style to iframe page
                    $(event.iframe).contents()
                        .find('body')
                        .css('overflow-x', 'hidden')
                        .css('margin', '0px');
                }
            }, this.iframe.nativeElement);
            this.frameInitialized = true;
        }
    }

    public handleChildMessage(message): void {
        if (message.state === 'done') {
            // TODO: should we done anything with the data.results?

            // mark in storage that this webview is done
            this.storage.markWebviewDone(this.url);

            // the child is done, we should close it in 2s
            setTimeout((): void => {
                this.done.emit();
            }, 2000);
        } else if (message.state === 'elevate') {
            // we want to reset the iframe size
            this.isOpen = false;

            this.elevate.emit();
        } else if (message.state === 'integration') {
            this.frame.sendParentMessage(message);
        } else if (message.state === 'init') {
            // the child widget is initialized, we should send it some of our info
            this.frame.sendChildMessage({
                type: 'init-config',
                data: this.utils.getConfig()
            }, this.iframe.nativeElement.contentWindow);
            this.frame.sendChildMessage({
                type: 'init-integration',
                data: this.utils.getIntegrations()
            }, this.iframe.nativeElement.contentWindow);
        }
    }

    public handleParentMessage(message): void {
        const supportedIntegrations = {
            'device-discovery': true,
            'log-upload': true,
            'config-upload': true,
            'support-upload': true,
            'enable-remote-access': true,
            'remote-access-enable': true,
            'disable-remote-access': true,
            'remote-access-disable': true,
            'status-remote-access': true,
            'remote-access-status': true
        };

        // we will check message is for current messengerpane by childId
        if (message.data && message.data.childId === this.frameId && message.type === 'integration') {
            // check message name
            if (supportedIntegrations[message.data.name] === true){
                /**
                 * If we want to send message back to child component, we have to remove childId here.
                 * The goal for childId is for checking which messengerpane component should pick it up
                 * and we want to take this messengerpane as a middle layer and send the message down to child component.
                 * In this case, the childId must be removed here or the message will be treated as a message from child component and send to wrong direction.
                 */
                delete message.data.childId;

                // send the message
                this.frame.sendChildMessage(message.data, this.iframe.nativeElement.contentWindow);
            }
        }
    }
}
